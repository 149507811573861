import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/Seo';
import ScrollToTop from '../components/ScrollToTop';
import Scroll from '../components/Scroll';
import Azimut from '../components/Casos/Azimut';
import Casacaracol from '../components/Casos/Casacaracol';
import Equivalenza from '../components/Casos/Equivalenza';

class Casos extends React.Component {
    render() {
        return (
            <LayoutEs>
                <Header />
                <SEO
                    title="Casos de éxito"
                    description="Casos de éxito de Beköme Digital. Nuestros clientes duermen tranquilos. Creamos tiendas online de alto rendimiento para empresas"
                    canonicalUrl="https://www.bekome.digital/casos-de-exito/"
                />
                <header className="masthead casos-exito d-flex">
                    <div className="container text-center my-auto">
                        <h1 className="mb-1 text-primary">Casos de éxito</h1>
                        <h4 className='mb-3 h4'>
                            Clientes que duermen tranquilos
                        </h4>
                        <Scroll type="id" element="estrategia" offset={0}>
                            <a className="text-white vert-move" href="#estrategia" title="Servicios">
                                <i className="icon-arrow-down"></i>
                            </a>
                        </Scroll>
                    </div>
                    <div className="overlay"></div>
                </header>

                <Azimut />

                <Equivalenza />

                <Casacaracol />

                <Footer />

                <ScrollToTop />
            </LayoutEs>
        )
    }
}

export default Casos;