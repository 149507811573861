import React from 'react';

export default function Equivalenza() {
    return (
        <section className="casos-section mt-5 mb-5" id="azimut">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 caso-item my-auto order-1 pl-5">                       
                        <div className="row caso-description mb-4">
                            <span className='caso-client pl-3'>Equivalenza</span>
                        </div>
                        <h2 className='caso-title mb-3'>
                            <a href='#'
                            className=' text-primary'
                            title='caso de éxtio Equivalenza'
                            >
                                Actualización a Magento 2.4, refactoring y desarrollo
                            </a>
                        </h2>
                        <div className='caso-tipo mb-5'>Desarrollo y migración de datos</div>
                        <div className='caso-link'>
                            <a href='#' className='d-block'>Ver caso de éxito</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 caso-bg equivalenza-bg order-0"></div>
                </div>
            </div>
        </section>
    )
}